import './landing_3.scss'
export default function Landing_3() {
  return (
    <div className='landing_3' onClick={ ()=> {window.open('https://btl001.com/', '_blank')}}>
      <div className='image_wrapper flex flex-col items-center justify-center'>
        <div className='w-full lg:w-[50%] 2xl:w-[40%]'>
          <img src='/landing-page.png' />
        </div>
      </div>
    </div>
  )
}
